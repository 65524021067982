import {createContext} from 'react';
import type {SortDescriptor} from '../../../../../common/resources/client/ui/tables/types/sort-descriptor';
import type {ColumnNbaConfig} from './column-nba-config';
import type {TableDataItem} from '../../../../../common/resources/client/ui/tables/types/table-data-item';
import { TableProps } from '@app_modules/tables/table-nba';

export type TableSelectionStyle = 'checkbox' | 'highlight';

export interface TableNbaContextValue<T extends TableDataItem = TableDataItem> {
  isCollapsedMode: boolean;
  selectedRows: (string | number)[];
  onSelectionChange: (keys: (string | number)[]) => void;
  sortDescriptor?: SortDescriptor;
  onSortChange?: (descriptor: SortDescriptor) => any;
  enableSelection?: boolean;
  enableSorting?: boolean;
  selectionStyle: TableSelectionStyle;
  data: T[];
  meta?: any;
  columns: ColumnNbaConfig<T>[];
  toggleRow: (item: T) => void;
  selectRow: (item: T | null, merge?: boolean) => void;
  hideBorder: boolean;
  hideHeaderRow: boolean;
  collapseOnMobile: boolean;
  onAction: TableProps<T>['onAction'];
  selectRowOnContextMenu: TableProps<T>['selectRowOnContextMenu'];
  cellHeight: string | undefined;
  headerCellHeight: string | undefined;
}
export const TableNbaContext = createContext<TableNbaContextValue>(null!);
