import {List, ListItem} from '@common/ui/list/list';
import {ReactNode, useContext, useState, useCallback, useEffect, Fragment} from 'react';
import { NbaDate } from './nba-date';
import moment from 'moment-timezone';
import { useAuth } from '@common/auth/use-auth';
import { setCookie } from '@common/utils/hooks/use-cookie';
import { RunPredictPayload, useRunPredict } from '@app_modules/requests/use-run-predict';
import { useForm } from 'react-hook-form';
import { useDialogContext } from '@common/ui/overlays/dialog/dialog-context';
import { Dialog } from '@common/ui/overlays/dialog/dialog';
import { DialogHeader } from '@common/ui/overlays/dialog/dialog-header';
import { Trans } from '@common/i18n/trans';
import { DialogBody } from '@common/ui/overlays/dialog/dialog-body';
import { Form } from '@common/ui/forms/form';
import { FormTextField } from '@common/ui/forms/input-field/text-field/text-field';
import { DialogFooter } from '@common/ui/overlays/dialog/dialog-footer';
import { Button } from '@common/ui/buttons/button';
import { useTrans } from '@common/i18n/use-trans';
import { DialogTrigger } from '@common/ui/overlays/dialog/dialog-trigger';
import { RunCircleIcon } from '@common/icons/material/RunCircle';
import { CircleIcon } from '@common/icons/material/Circle';
import { useSettings } from '@common/core/settings/use-settings';
import { DownloadIcon } from '@common/icons/material/Download';
import { useDownloadOdds } from '@app_modules/requests/use-download-odds';
import { downloadFile } from '@app_modules/requests/use-download-file';


interface NbaSideLeftProps {
  nbaDates: NbaDate[],
  changeDate: any,
  latestDate: any,
}

export function NbaSideLeft({
  nbaDates,
  changeDate,
  latestDate,
}: NbaSideLeftProps) {

  const {user} = useAuth();
  const user_id = user?.id;

  const [nbaDate, setNbaDate] = useState(latestDate);

  const selectedDate = (nba_date: any) => {
    if (user_id && nba_date != latestDate) {
      setCookie('rate-sheet-tab-'+user_id, '0');
    }

    changeDate(nba_date);
    setNbaDate(nba_date);

    if (user_id) {
      setCookie('nba-date-'+user_id, nba_date.toString());
    }
  }

  useEffect(() => {
    setNbaDate(latestDate);
  }, [latestDate]);

  const dateArray = nbaDates.map(item => item.created_date);

  const changeTargetDate = (targetDate: string) => {
    setNbaDate(targetDate);
  };

  const downloadOdds = useDownloadOdds(nbaDate);

  
  return (
    <aside className="sticky top-10 flex-shrink-0 lg:block border-r-2 border-t-2">
      <List padding="p-0" className='scroll-p-8 overflow-y-scroll h-800 min-h-800 bg-gray-300'>
        {nbaDates.map((item: NbaDate, index: number) => (
          <Item 
            key={item.created_date}
            panel={item.created_date}
            isSelected={nbaDate==item.created_date}
            changeDate={selectedDate}
          >
          <div>
            <div className={user_id == 1 &&  index+1 == nbaDates.length ? 'h-60 rounded-panel shadow py-2 px-2 cursor-pointer':'h-30 rounded-panel shadow py-2 px-2 cursor-pointer'}>
              <span className={nbaDate==item.created_date ? 'inline-block align-middle text-[#3B81F6]' : 'inline-block align-middle'}>
              <b className='mr-6'>{moment.utc(item.created_date).format('MM/DD/YYYY')}</b>
              {user_id == 1 &&  index+1 == nbaDates.length? (
                <>
                <br/>
                <Fragment>
                <DialogTrigger type="modal" key={`dailog-${index}`}>
                  <Button size={'xs'} variant="outline" color="orange" className='mr-10'>
                    <CircleIcon/>
                  </Button>
                  <RunPredictDialog key={`dailog-copy-${index}`}/>
                </DialogTrigger>
                <Button
                  variant="outline"
                  color="primary"
                  size={'2xs'}
                  onClick={() => {
                    downloadOdds.mutate(nbaDate, {
                      onSuccess: (response: any) => {
                        downloadFile(nbaDate+'.json', response)
                      },
                    });
                  }}
                >
                  <DownloadIcon/>
                </Button>
                </Fragment>
                </>
              ):(<></>)}
              
              </span>
            </div>
          </div>
          </Item>
        ))}
        
      </List>
    </aside>
  );
}

interface ItemProps {
  children: ReactNode;
  isSelected?: boolean;
  panel: string;
  changeDate: any,
}
function Item({children, isSelected, panel, changeDate}: ItemProps) {
  return (
    <ListItem
      className={isSelected ?'bg-[#F4F8FE]' : undefined}
      padding="px-4 py-10"
      onSelected={() => {
        changeDate(panel);
      }}
    >
      {children}
    </ListItem>
  );
}


export function RunPredictDialog() {
  const {nba_settings: setting_nba} = useSettings();
  const [prompts, setPrompts] = useState<any>(setting_nba? JSON.parse(setting_nba):[]);
  const d = moment().day();
  const predict_prompt = d == 0 || d == 6  ? prompts.nba_analysis_weekend_prompt : prompts.nba_analysis_prompt;
  
  const form = useForm<RunPredictPayload>({
    defaultValues: {prompt: predict_prompt, compare_prompt: 'Confirm Best Bet is the same team from source [document 1] and [current doc] only'},
  });
  const {formId, close} = useDialogContext();
  const runPredict = useRunPredict(form);
  const {trans} = useTrans();

  return (
    <Dialog size='xl'>
      <DialogHeader>
        <Trans message="Run Prediction NBA" />
      </DialogHeader>
      <DialogBody>
        <Form
          form={form}
          id={formId}
          onSubmit={() => {
            runPredict.mutate(form.getValues());
            
          }}
        >
          <FormTextField
            name="prompt"
            autoFocus
            label={trans({message: 'Prompt'})}
            inputElementType="textarea"
            rows={15}
            required
          />

          <FormTextField
            name="compare_prompt"
            autoFocus
            label={trans({message: 'Compare prompt'})}
            inputElementType="textarea"
            rows={4}
            required
          />
        </Form>
      </DialogBody>
      <DialogFooter>
        <Button variant="text" onClick={close}>
          <Trans message="Cancel" />
        </Button>
        <Button
          variant="flat"
          color="orange"
          type="submit"
          form={formId}
          disabled={runPredict.isPending}
        >
          <Trans message="Run" />
        </Button>
      </DialogFooter>
    </Dialog>
  );
}