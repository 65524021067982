import {FieldValues, SubmitHandler, UseFormReturn} from 'react-hook-form';
import clsx from 'clsx';
import React, {Fragment, ReactNode} from 'react';
import {useStickySentinel} from '@common/utils/hooks/sticky-sentinel';
import {Form} from '@common/ui/forms/form';
import {Button} from '@common/ui/buttons/button';
import {Trans} from '@common/i18n/trans';

interface Props<T extends FieldValues> {
  title: ReactNode;
  subTitle?: ReactNode;
  children: ReactNode;
  actions?: ReactNode;
  backButton?: ReactNode;
  disableSaveWhenNotDirty?: boolean;
  wrapInContainer?: boolean;
}
export function DetailResourceLayout<T extends FieldValues>({
  title,
  subTitle,
  children,
  actions,
  backButton,
  disableSaveWhenNotDirty = false,
  wrapInContainer = true,
}: Props<T>) {
  const {isSticky, sentinelRef} = useStickySentinel();
  
  return (
    <Fragment>
      <div ref={sentinelRef} />
      <div
        className={clsx(
          'sticky top-0 z-10 my-12 transition-shadow md:my-24',
          isSticky && 'bg shadow',
        )}
      >
        <div
          className={clsx(
            'flex items-center gap-24 py-4 md:items-start',
            wrapInContainer && ' mx-auto px-4',
          )}
        >
          {backButton}
          {/* <div className="overflow-hidden overflow-ellipsis md:mr-64">
            <h2 className="overflow-hidden overflow-ellipsis whitespace-nowrap text-xl md:text-3xl">
              {title}
            </h2>
            {subTitle && <div className="mt-4">{subTitle}</div>}
          </div> */}
          <div className="mr-auto"></div>
          {actions}
        </div>
      </div>
      <div
        className={
          wrapInContainer ? ' mx-auto px-4 pb-24' : undefined
        }
      >
        <div className="rounded">{children}</div>
      </div>
    </Fragment>
  );
}
