import {useMutation} from '@tanstack/react-query';
import {apiClient, queryClient} from '@common/http/query-client';
import {BackendResponse} from '@common/http/backend-response/backend-response';
import {toast} from '@common/ui/toast/toast';
import {message} from '@common/i18n/message';
import {showHttpErrorToast} from '@common/utils/http/show-http-error-toast';

interface Response extends BackendResponse {}

interface Payload {
  id: number|string;
  key: string;
  val: any; 
}

export function useUpdateTeamField(id: number|string, key: string, val: string) {
  return useMutation({
    mutationFn: ({id, key, val}: Payload) =>
      updateValue({id: id, key: key, val: val}),
      onSuccess: (response: any, payload) => {
      if (response.message) {
        toast(
          message(response.message),
        );
      } else {
        toast(
          message(`You has just updated.`),
        );
      }
    },
    onError: err => showHttpErrorToast(err),
  });
}

function updateValue({
  id, 
  key,
  val,
}: Payload): Promise<Response> {
  return apiClient
    .post(`nbas/${id}/update-value`, {key: key, val: val})
    .then(r => r.data);
}

