import { FullPageLoader } from '@common/ui/progress/full-page-loader';
import { useNbaTeams } from '@app_modules/requests/use-nba-teams';
import { HistoryInfo } from './history-info';
import { DetailInfo } from './detail-info';


interface NbaPredictProps {
  nbaDate: string,
}

export function NbaPredictPage({
  nbaDate,
}: NbaPredictProps) {

    const query = useNbaTeams(nbaDate);
  
    if (query.status !== 'success') {
      return <FullPageLoader />;
    }
    return <PageContent nbaTeams={query.data} nbaDate={nbaDate}/>;
}
  
interface PageContentProps {
  nbaTeams: any;
  nbaDate: string;
}
  
function PageContent({nbaTeams, nbaDate}: PageContentProps) {


  return (
    <>
      <DetailInfo items={nbaTeams} nbaDate={nbaDate}/>
    </>
  );
}