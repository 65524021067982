import { useUserTimezone } from '@common/i18n/use-user-timezone';
import { ViewListIcon } from '@common/icons/material/ViewList';
import { Button } from '@common/ui/buttons/button';
import { DialogTrigger } from '@common/ui/overlays/dialog/dialog-trigger';
import moment from 'moment-timezone';
import {Fragment, useEffect, useId, useState} from 'react';
import { HistoryDialog } from './nba-index-page';
import { DownloadIcon } from '@common/icons/material/Download';

interface Props {
  items: any;
}

export function HistoryInfo({items}: Props) {
  const timezone = useUserTimezone();

  const [keys, setKeys] = useState<any>([]);
  useEffect(() => {
    if (items) {
      setKeys(items && items[0] && items[0][0] ? Object.keys(items[0][0]) : (items && items[0] ? Object.keys(items[0]) : Object.keys(items)));
    }
  }, [items]);

  if (items) {
    return (
      <Fragment>

        <div className="flex flex-wrap">
          <div className='w-full'>
          {keys.length> 1 ? (<>
            <table className="w-full file">
              <thead className="bg-white border-b sticky top-0 bg-slate-300">
                <tr className='bg-gray-300'>
                  {keys.map((header: string, index: number) => (
                    <>
                    {header != 'start_time' && header != 'home_team' && header != 'away_team'? (<>
                      <th key={`h-${header}`} scope="col" className={index == 0 ? "bg-gray-300 text-md font-medium text-gray-900 px-6 py-4 pl-20 text-left": "bg-gray-300 text-md font-medium text-gray-900 px-6 py-4 text-left"}>
                        {capitalizeFirstLetter(header.toString())}
                      </th>
                    </>):(<></>)}
                    </>
                  
                  ))}
                </tr>
              </thead>

              <tbody className="overflow-y-auto">
                {items.map((item: any, idx: number) => (
                <tr key={`row-${idx}`} className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                  {keys.map((header: string, index: number) => {
                    const val = item[0] ? item[0][header] : item[header];
                    const start_time = item[0] ? item[0]['start_time'] : item['start_time'];
                    const createdAt = item[0] ? moment.utc(item[0]['Created At']).format('YYYYMMDDHHmm') : moment.utc(item['Created At']).format('YYYYMMDDHHmm');
                    if (val != null && header == 'odds_data') {
                      return (
                        <span>
                          <Button
                            className='mt-10'
                            variant="outline"
                            color="primary"
                            size={'2xs'}
                            onClick={() => {
                              downloadFile(`${createdAt}.json`, val);
                            }}
                          >
                            <DownloadIcon/>
                          </Button>
                        </span>
                      );
                    } else if (val != null && header != 'home_team' && header != 'away_team' && header != 'start_time' && header != 'history') {
                      if (typeof val === 'string' || val instanceof String || typeof val === 'number') {

                        const text_red = moment.utc(start_time).format('MM/DD/YYYY HH:mm') == moment().format('MM/DD/YYYY HH:mm') ? 'text-red': ''

                        return (
                          <td key={`td-${idx}-${index}`} className={index==0? `text-sm font-extrabold text-gray-900 font-light px-6 py-4 pl-20 whitespace-nowrap ${text_red}`:`text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap ${text_red}`}>
                            {header == 'game' ? (<>
                            <GameTeam key={`g-${idx}-${index}`} val={val}/>
                            </>):(<>
                              {header == 'history' ? (<>
                                <Fragment>
                                  <DialogTrigger type="modal">
                                    <Button size={'3xs'} variant="outline" color="orange">
                                      <ViewListIcon/>
                                    </Button>
                                    <HistoryDialog key={`history-${idx}-${index}`} items={val}/>
                                  </DialogTrigger>
                                </Fragment>
                              </>):(<>
                                {header == 'game_date_time_pst' ? (<>
                                  {moment.utc(val as string).format('MM/DD/YYYY HH:mm')}
                                </>):(<>
                                {val}
                                </>)}
                              </>)}
                            </>)}
                            
                          </td>
                        );
                      }
                    }
                  })}
                </tr>
                ))}
              </tbody>
            </table>
          </>):(<></>)}
          </div>
        </div>
      </Fragment>
    );
  }
}

function GameTeam({val}:{val: any}) {
  let [string1, string2] = val.split("\n");
  
  return (
    <>
    <span className='border-b-1 pb-4'>{string1}</span><br/>

    <span className='pt-4'>{string2}</span>
    </>
  );
}

function capitalizeFirstLetter(s: string) {
  let newStr = s.replaceAll("_", " ").toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
  if (newStr == 'Game Date Time Pst') {
    return "Game Date Time (PST)";
  } else if (newStr == 'Home Team Point Spread') {
    return "Home Pts Spread";
  }
  
  return newStr;
}

export function downloadFile(fileName: string, jsonArray: any[]) {
  // Convert the JSON array to a string with proper formatting
  const jsonString = JSON.stringify(jsonArray, null, 2);

  // Create a blob from the JSON string
  const blob = new Blob([jsonString], { type: 'application/json' });

  // Create blob link to download
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);

  // Append to HTML page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link.parentNode?.removeChild(link);
  // Release the object URL
  window.URL.revokeObjectURL(url);
}