import {useMutation, useQuery} from '@tanstack/react-query';
import {apiClient, queryClient} from '@common/http/query-client';
import {BackendResponse} from '@common/http/backend-response/backend-response';
import { showHttpErrorToast } from '@common/utils/http/show-http-error-toast';



const endpoint = (prediction_id: number | string) => `stock-predictions/${prediction_id}/download`;

interface Response extends BackendResponse {}

interface Payload {
  prediction_id: number | string;
  file_name: string;
}

export function useDownloadFile() {

  return useMutation({
    mutationFn: (payload: Payload) => fetchFile(payload),
    onError: err => showHttpErrorToast(err),
  });
}

function fetchFile({prediction_id, file_name}: Payload): Promise<Response> {
  return apiClient.post(endpoint( prediction_id), {file_name: file_name}, {responseType: 'blob'}).then(r => r.data);
}

export function downloadFile(fileName: string, blob: any) {
  // Create blob link to download
  const url = window.URL.createObjectURL(
    new Blob([blob]),
  );
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute(
    'download',
    fileName,
  );

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link.parentNode?.removeChild(link);
}