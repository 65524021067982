import {useQuery} from '@tanstack/react-query';
import {BackendResponse} from '@common/http/backend-response/backend-response';
import {apiClient} from '@common/http/query-client';

const Endpoint = (nbaDate: string) => `nbas/${nbaDate}/teams`;

interface Response extends BackendResponse {}

export function useNbaTeams(nbaDate: string) {
  return useQuery({
    queryKey: [Endpoint(nbaDate)],
    queryFn: () => fetchNbaTeams(nbaDate),
  });
}

function fetchNbaTeams(nbaDate: string): Promise<Response> {
  return apiClient.get(Endpoint(nbaDate)).then(response => response.data.nba_teams);
}
