import { useUserTimezone } from '@common/i18n/use-user-timezone';
import { ViewListIcon } from '@common/icons/material/ViewList';
import { Button } from '@common/ui/buttons/button';
import { DialogTrigger } from '@common/ui/overlays/dialog/dialog-trigger';
import moment from 'moment-timezone';
import {Fragment, useEffect, useId, useState} from 'react';
import { HistoryDialog } from './nba-index-page';
import { useUpdateTeamField } from '@app_modules/requests/use-update-team-field';
import { TextField } from '@common/ui/forms/input-field/text-field/text-field';
import { Checkbox } from '@common/ui/forms/toggle/checkbox';
import { Trans } from '@common/i18n/trans';
import { queryClient } from '@common/http/query-client';

interface Props {
  items: any;
  nbaDate: string
}

export function DetailInfo({items, nbaDate}: Props) {
  const timezone = useUserTimezone();

  const [keys, setKeys] = useState<any>([]);
  useEffect(() => {
    if (items) {
      setKeys(items && items[0] && items[0][0] ? Object.keys(items[0][0]) : (items && items[0] ? Object.keys(items[0]) : Object.keys(items)));
    }
  }, [items]);

  if (items) {
    return (
      <Fragment>

        <div className="flex flex-wrap">
          <div className='w-full'>
          {keys.length> 1 ? (<>
            <table className="w-full file">
              <thead className="bg-white border-b sticky top-0 bg-slate-300">
                <tr className='bg-gray-300'>
                  {keys.map((header: string, index: number) => (
                    <>
                    {header != 'start_time' && header != 'id' && header != 'Created At' && header != 'odds_data'? (<>
                      <th scope="col" className={index == 0 ? "bg-gray-300 text-md font-medium text-gray-900 px-6 py-4 pl-20 text-left": "bg-gray-300 text-md font-medium text-gray-900 px-6 py-4 text-left"}>
                        {capitalizeFirstLetter(header.toString())}
                      </th>
                    </>):(<></>)}
                    </>
                  
                  ))}
                </tr>
              </thead>

              <tbody className="overflow-y-auto">
                {items.map((item: any, idx: number) => (
                <tr key={`row-${idx}-${nbaDate}`} className="bg-white border-b transition duration-300 ease-in-out hover:bg-gray-100">
                  {keys.map((header: string, index: number) => {
                    const val = item[0] ? item[0][header] : item[header];
                    const start_time = item[0] ? item[0]['start_time'] : item['start_time'];
                    const id = item[0] ? item[0]['id'] : item['id'];
                    const createdAt = item[0] ? item[0]['Created At'] : item['Created At'];
                    if (val != undefined && header != 'start_time' && header != 'id' && header != 'Created At' && header != 'odds_data') {
                      if (typeof val === 'boolean' || typeof val === 'string' || val instanceof String || typeof val === 'number' || header == 'history') {
                        const text_red = moment.utc(start_time).format('MM/DD/YYYY HH:mm') == moment().format('MM/DD/YYYY HH:mm') ? 'text-red': ''
                        return (
                          <td key={`td-${idx}-${index}`} className={index==0? `text-sm font-extrabold text-gray-900 font-light px-6 py-4 pl-20 whitespace-nowrap ${text_red}`:`text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap ${text_red}`}>
                            <FieldValue nbaDate={nbaDate} key={`field-${idx}-${index}`} id={id} val={val} header={header} createt_at={createdAt}/>
                          </td>
                        );
                      }
                    }
                  })}
                </tr>
                ))}
                <Summary keys={keys} items={items} today={nbaDate}/>
              </tbody>
            </table>
          </>):(<></>)}
          </div>
        </div>
      </Fragment>
    );
  }
}

function calculateWinLossPercentage(nba_teams: any[], today: string): number {
  // Define the start and end of "today" based on the input parameter (UTC)
  const todayStart = new Date(`${today}T00:00:00Z`).getTime();
  const todayEnd = new Date(`${today}T23:59:59.999Z`).getTime(); // End of day
  // Step 1: Filter for games that occur today (based on game_date_time_pst)
  const todayGames = nba_teams.filter((game) => {
    const gameDateTime = new Date(game.game_date_time_pst).getTime();
    return gameDateTime >= todayStart && gameDateTime <= todayEnd;
  });

  // Step 2: Filter for games that have ended (start_time < current time)
  const currentTime = new Date().getTime(); // Real current time, or pass as param if needed
  const endedGames = todayGames.filter((game) => {
    const startTime = new Date(game.start_time).getTime();
    return currentTime > startTime;
  });
  const endedTodayGames = endedGames.length;

  // Step 3: Count wins and losses among ended games
  const wins = endedGames.filter((game) => game.win_loss === true).length;
  const losses = endedTodayGames - wins;

  // Step 4: Calculate differential (wins - losses) * 100
  const differential = endedTodayGames ? (wins - losses) * 100 : 0;

  // Step 5: Return rounded to 2 decimal places
  return Number(differential.toFixed(2));
}


function Summary({keys, items, today}:{keys: any, items: any, today: string}) {

  const winLossPercentage = calculateWinLossPercentage(items, today);

  return (
    <>
    <tr className='bg-gray-300'>
        {keys.map((header: string, index: number) => (
          <>
          {header != 'start_time' && header != 'id' && header != 'Created At'? (<>
            <td scope="col" className={index == 0 ? "bg-gray-300 text-md font-medium text-gray-900 px-6 py-4 pl-20 text-left": "bg-gray-300 text-md font-medium text-gray-900 px-6 py-4 text-left"}>
              {header == 'final_team_win' ? (<><span className=' text-xl mr-10'>
                <Trans message="Pick W/L"/>:
              </span> 
              <span className={winLossPercentage >= 0 ? 'text-green text-xl': 'text-red text-xl'}>
              {winLossPercentage}%
              
              </span>
              </>):(<></>)}
              {header == 'win_loss' ? (<></>):(<></>)}
            </td>
          </>):(<></>)}
          </>
        ))}
      </tr>
    </>
  );
}

function FieldValue({id, nbaDate, header,val, createt_at}:{id: number, nbaDate: string, header: string, val: any, createt_at: string}) {

  if (header == 'history') {
    return (<>
    <Fragment>
      <DialogTrigger type="modal">
        <Button size={'3xs'} variant="outline" color="orange">
          <ViewListIcon/>
        </Button>
        <HistoryDialog items={val}/>
      </DialogTrigger>
    </Fragment>
    </>);
  } else if (header == 'game') {
    return (
      <GameTeam val={val}/>
    );
  } else if(header == 'final_score') {
    return (
      <UpdateTeamField val={val} id={id} field_name={header} nbaDate={nbaDate}/>
    );
  } else if(header == 'final_team_win') {
    return (
      <UpdateTeamField val={val} id={id} field_name={header} nbaDate={nbaDate}/>
    );
  } else if(header == 'win_loss') {
    return (
      <UpdateTeamField val={val} id={id} field_name={header} nbaDate={nbaDate}/>
    );
  } else if(header == 'spread_win_loss') {
    return (
      <UpdateTeamField val={val} id={id} field_name={header} nbaDate={nbaDate}/>
    );
  } else if (header == 'game_date_time_pst') {
    const text_red = moment.utc(val).format('YYYY-MM-DD HH:mm') <= moment().format('YYYY-MM-DD HH:mm') ? 'text-red': ''
    return (<>
      <span className={`${text_red}`}>{moment.utc(val).format('MM/DD/YYYY HH:mm')}</span><br/>
      <span className='text-2sm'>{createt_at}</span>
    </>);
  } else {
    return (<>{val}</>);
  }
}

function UpdateTeamField({nbaDate, id, field_name, val}:{nbaDate: string, id:number|string, field_name: string, val: any}) {

  const [value, setValue] = useState(val);
  const mutation = useUpdateTeamField(id, field_name, val); // Hook at top level

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(newValue);
    mutation.mutate({ id, key: field_name, val: newValue });
  };

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.checked;
    setValue(newValue);
    mutation.mutate({ id, key: field_name, val: newValue }, {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [`nbas/${nbaDate}/teams`],
        });
      },
    });
  };

  if (field_name == 'win_loss' || field_name == 'spread_win_loss') {
    
    return (
      <Fragment>
        <span>
          <div className='item-center ml-10 mr-10'>
          <Checkbox
          size='lg'
          name={`${id}_${field_name}`}
          key={`check_${id}_${field_name}`}
          checked={value}
          onChange={handleCheck}         
        />
          </div>
        </span>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <span>
          <div className='item-center ml-10 mr-10'>
          <TextField
          className='form-input w-full'
          name={`${id}_${field_name}`}
          key={`${id}_${field_name}`}
          value={value}
          onChange={handleChange}         
        />
          </div>
        </span>
      </Fragment>
    );
  }
}

function GameTeam({val}:{val: any}) {
  let [string1, string2] = val.split("\n");
  
  return (
    <>
    <span className='border-b-1 pb-4'>{string1}</span><br/>

    <span className='pt-4'>{string2}</span>
    </>
  );
}

function capitalizeFirstLetter(s: string) {
  let newStr = s.replaceAll("_", " ").toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
  if (newStr == 'Game Date Time Pst') {
    return "Game Date Time (PST)";
  } else if (newStr == 'Home Team Point Spread') {
    return "Home Pts Spread";
  } else if (newStr == 'Win Loss') {
    return "Pick W/L";
  } else if (newStr == 'Spread Win Loss') {
    return "Totals W/L";
  }

  
  
  return newStr;
}
