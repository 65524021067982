import {useQuery} from '@tanstack/react-query';
import {BackendResponse} from '@common/http/backend-response/backend-response';
import {apiClient} from '@common/http/query-client';

const Endpoint = () => `nbas-date`;

export interface FetchDataResponse extends BackendResponse {
  //  nba_date: NbaDate[];
  pagination: any;
}

export function useNbaDate() {
  return useQuery({
    queryKey: [Endpoint()],
    queryFn: () => fetchNbaDate(),
  });
}

function fetchNbaDate(): Promise<FetchDataResponse> {
  
  return apiClient.get(Endpoint()).then(response => response.data);
}
