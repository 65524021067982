import {useNbas} from '../use-nbas';
import {useAuth} from '@common/auth/use-auth';
import {Trans} from '@common/i18n/trans';
import React, {Fragment, ReactNode, useCallback, useEffect, useState} from 'react';
import {useTrans} from '@common/i18n/use-trans';
import {message} from '@common/i18n/message';
import {Footer} from '@common/ui/footer/footer';
import {AnimatePresence, m} from 'framer-motion';
import {useAccountUsage} from '@app/editor/use-account-usage';
import {AdHost} from '@common/admin/ads/ad-host';
import {StaticPageTitle} from '@common/seo/static-page-title';
import {useNavigate} from '@common/utils/hooks/use-navigate';
import { NbaViewPage } from './nba-view-page';
import { DashboardNavbar } from '@app/dashboard/dashboard-navbar';


export function NbaPage() {
  const {data: usage} = useAccountUsage();
  const navigate = useNavigate();
  const {trans} = useTrans();

  return (
    <Fragment>
      <StaticPageTitle>
        <Trans message="NBA Prediction" />
      </StaticPageTitle>
      <div className="flex h-screen flex-col overflow-y-scroll">
        <DashboardNavbar />
        <div className="relative">
          <div className=" mx-auto flex-auto px-12">
            <div className="items-center gap-12 py-361">
              <div className='mr-auto'>
              <AdHost slot="dashboard_top" className="mb-36" />
              <AnimatePresence initial={false} mode="wait">
                <NbaViewPage  />
              
              </AnimatePresence>
              <AdHost slot="dashboard_bottom" className="mt-36" />
              </div>
            </div>
          </div>
        </div>
        
        <Footer className="mt-40 px-40" />
      </div>
    </Fragment>
  );
}




