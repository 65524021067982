import {FullPageLoader} from '@common/ui/progress/full-page-loader';
import {Trans} from '@common/i18n/trans';
import { useEffect, useState } from 'react';
import { NbaSideLeft } from './nba-side-left';
import { useNbaDate } from '@app_modules/requests/use-nba-date';
import { NbaDate } from './nba-date';
import moment, { now } from 'moment-timezone';
import { DetailResourceLayout } from './detail-resource-layout';
import { useUserTimezone } from '@common/i18n/use-user-timezone';
import { useAuth } from '@common/auth/use-auth';
import { getCookie } from '@common/utils/hooks/use-cookie';
import { NbaPage } from './nba-index-page';
import { NbaPredictPage } from './nba-predict-page';

export function NbaViewPage() {
  const query = useNbaDate();

  const [dates, setDates] = useState<any>([]);

  const timezone = useUserTimezone();
  useEffect(() => {
    if (query.data && query.data.pagination) {
      let arr_dates = query.data.pagination.data;
      
      const currentDate: string = moment.utc().tz(timezone).format('YYYY-MM-DD');
      const dateExists: boolean = arr_dates.some((item: NbaDate) => item.created_date === currentDate);
      if (!dateExists) {
        arr_dates.push({ created_date: currentDate });
      }
      setDates(arr_dates);
    }
  }, [query.data]);

  if (query.status !== 'success' || dates.length == 0) {
    return <FullPageLoader />;
  }

  return <PageContent nbaDates={dates}/>;
}

interface PageContentProps {
  nbaDates: NbaDate[];
}
function PageContent({ nbaDates}: PageContentProps) {
    
  const [nbaDate, setNbaDate] = useState<any>(null);
  const [timeChange, setTimeChange] = useState<any>(null);

  const changeDate = (date: any, select_tab: string) => {
    setNbaDate(date);
    setTimeChange(now());
  };


  const [latestDate, setLatestDate] = useState<any>();
  //nbaDates ? nbaDates.at(-1) : ''
  const {user} = useAuth();
  const user_id = user?.id;
  const default_nba_date = user_id && getCookie('nba-date-'+user_id) ? getCookie('nba-date-'+user_id) : '';


  useEffect(() => {
    if (nbaDates.at(-1)) {
      const item = nbaDates.at(-1);
      setLatestDate(item);
      setNbaDate(default_nba_date ? default_nba_date : item?.created_date);
    }
  }, [nbaDates]);

  return (
    <DetailResourceLayout
      title={
        <Trans message="NBA Prediction"/>
      }
      // isLoading={viewNba.isPending}
    >
      <div className="bg-gray-50 flex ">
        <div className="w-full mx-auto grid grid-cols-12 bg-gray-200">
          <div className="col-span-1 border-b-2 text-center">
            <NbaSideLeft  nbaDates={nbaDates} changeDate={changeDate} latestDate={default_nba_date ? default_nba_date : latestDate?.created_date}/>
          </div>
          <div className="col-span-11 bg-gray-300">
            <NbaPredictPage nbaDate={nbaDate}/>
            {/* <NbaPage nbaDate={nbaDate} timeChange={timeChange}/> */}
          </div>
        </div>
      </div>
    </DetailResourceLayout>
  );
  
}
