import {useMutation, useQuery} from '@tanstack/react-query';
import {apiClient, queryClient} from '@common/http/query-client';
import {BackendResponse} from '@common/http/backend-response/backend-response';
import { showHttpErrorToast } from '@common/utils/http/show-http-error-toast';



const endpoint = (nbaDate: string) => `nbas/${nbaDate}/download-odds`;

interface Response extends BackendResponse {}

export function useDownloadOdds(nbaDate: string) {

  return useMutation({
    mutationFn: (nbaDate: string) => fetchOdds(nbaDate),
    onError: err => showHttpErrorToast(err),
  });
}

function fetchOdds(nbaDate: string): Promise<Response> {
  return apiClient.post(endpoint(nbaDate), {}, {responseType: 'blob'}).then(r => r.data);
}
