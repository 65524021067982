import {Trans} from '@common/i18n/trans';
import {DataTableEmptyStateMessage} from '@common/datatable/page/data-table-emty-state-message';
import softwareEngineerSvg from '@common/admin/tags/software-engineer.svg';
import { useUserTimezone } from '@common/i18n/use-user-timezone';
import moment from 'moment-timezone';
import { DataNbaTablePage } from '../tables/data-nba-table-page';
import { Fragment, useState } from 'react';
import { Button } from '@common/ui/buttons/button';
import { DialogTrigger } from '@common/ui/overlays/dialog/dialog-trigger';
import { NbaTeam } from './nba-team';
import { ColumnNbaConfig } from '@app_modules/tables/column-nba-config';
import { Dialog } from '@common/ui/overlays/dialog/dialog';
import { ReversalTeamPayload, useReversalTeam } from '@app_modules/requests/use-reversal-team';
import { useDialogContext } from '@common/ui/overlays/dialog/dialog-context';
import { useTrans } from '@common/i18n/use-trans';
import { useForm } from 'react-hook-form';
import { DialogHeader } from '@common/ui/overlays/dialog/dialog-header';
import { DialogBody } from '@common/ui/overlays/dialog/dialog-body';
import { Form } from '@common/ui/forms/form';
import { FormTextField, TextField } from '@common/ui/forms/input-field/text-field/text-field';
import { DialogFooter } from '@common/ui/overlays/dialog/dialog-footer';
import { ReversalTotalPayload, useReversalTotal } from '@app_modules/requests/use-reversal-total';
import { useUpdateTeamField } from '@app_modules/requests/use-update-team-field';
import { HistoryInfo } from './history-info';
import { DetailsIcon } from '@common/icons/material/Details';
import { SearchIcon } from '@common/icons/material/Search';
import { ViewListIcon } from '@common/icons/material/ViewList';


export function NbaPage({nbaDate, timeChange}:{nbaDate:any, timeChange: any}) {
  // const navigate = useNavigate();
  const timezone = useUserTimezone();
  
  const [curentPrice, setCurentPrice] = useState(0)
  const changeCurrentPrice = (price: number) => {
    setCurentPrice(price);
  }

  const columnConfig: ColumnNbaConfig<NbaTeam>[] = [
    {
      key: 'start_time',
      allowsSorting: false,
      align: 'center',
      width: 'flex-shrink-0 w-[8%]',
      header: () => <Trans message="Time"/>,
      classExt: (team) => moment.utc(team.start_time).tz(timezone).format('MM/DD/YYYY HH:mm') == moment().tz(timezone).format('MM/DD/YYYY HH:mm') ? '': 'text-red',
      body:  team => {
        return (
          <Fragment>
            <span>
              <div className='text-center ml-4 mr-4'>
                {moment.utc(team.start_time).tz(timezone).format('MM/DD/YYYY HH:mm')}
              </div>
            </span>
          </Fragment>
        );
      },    
    },

    {
      key: 'team_name',
      allowsSorting: false,
      //align: 'start',
      width: 'flex-shrink-0 w-[8%]',
      header: () => <Trans message="Team"/>,
      classExt: (team) => moment.utc(team.start_time).tz(timezone).format('MM/DD/YYYY HH:mm') == moment().tz(timezone).format('MM/DD/YYYY HH:mm') ? '': 'text-red',
      body:  team => {
        return (
          <Fragment>
            <span>
              <div className='text-left ml-4 mr-4'>
                {team.team_name}
              </div>
            </span>
          </Fragment>
        );
      },    
    },

    {
      key: 'opener',
      allowsSorting: false,
      //align: 'start',
      width: 'flex-shrink-0 w-[4%]',
      header: () => <Trans message="Opener"/>,
      classExt: (team) => moment.utc(team.start_time).tz(timezone).format('MM/DD/YYYY HH:mm') == moment().tz(timezone).format('MM/DD/YYYY HH:mm') ? '': 'text-red',
      body:  team => {
        return (
          <Fragment>
            <span>
              <div className='text-left ml-4 mr-4'>
                {team.opener}
              </div>
            </span>
          </Fragment>
        );
      },    
    },

    {
      key: 'current',
      allowsSorting: false,
      //align: 'start',
      width: 'flex-shrink-0 w-[4%]',
      header: () => <Trans message="Current"/>,
      classExt: (team) => moment.utc(team.start_time).tz(timezone).format('MM/DD/YYYY HH:mm') == moment().tz(timezone).format('MM/DD/YYYY HH:mm') ? '': 'text-red',
      body:  team => {
        return (
          <Fragment>
            <span>
              <div className='text-left ml-4 mr-4'>
                {team.current}
              </div>
            </span>
          </Fragment>
        );
      },    
    },

    {
      key: 'updated_at',
      allowsSorting: false,
      //align: 'start',
      width: 'flex-shrink-0 w-[8%]',
      header: () => <Trans message="Last Prediction"/>,
      classExt: (team) => moment.utc(team.start_time).tz(timezone).format('MM/DD/YYYY HH:mm') == moment().tz(timezone).format('MM/DD/YYYY HH:mm') ? '': 'text-red',
      body:  team => {
        return (
          <Fragment>
            <span>
              <div className='text-left ml-4 mr-4'>
                {moment.utc(team.updated_at).tz(timezone).format('MM/DD/YYYY HH:mm')}
              </div>
            </span>
          </Fragment>
        );
      },    
    },

    {
      key: 'team_prediction',
      allowsSorting: false,
      //align: 'start',
      width: 'flex-shrink-0 w-[8%]',
      header: () => <Trans message="Team Prediction"/>,
      classExt: (team) => moment.utc(team.start_time).tz(timezone).format('MM/DD/YYYY HH:mm') == moment().tz(timezone).format('MM/DD/YYYY HH:mm') ? '': 'text-red',
      body:  team => {
        return (
          <Fragment>
            <span>
              <div className='text-left ml-4 mr-4'>
                {team.team_prediction}
              </div>
            </span>
          </Fragment>
        );
      },    
    },

    {
      key: 'total_prediction',
      allowsSorting: false,
      //align: 'start',
      width: 'flex-shrink-0 w-[7%]',
      header: () => <Trans message="Total Prediction"/>,
      classExt: (team) => moment.utc(team.start_time).tz(timezone).format('MM/DD/YYYY HH:mm') == moment().tz(timezone).format('MM/DD/YYYY HH:mm') ? '': 'text-red',
      body:  team => {
        return (
          <Fragment>
            <span>
              <div className='text-left ml-4 mr-4'>
                {team.total_prediction}
              </div>
            </span>
          </Fragment>
        );
      },    
    },

    {
      key: 'reversal_team',
      allowsSorting: false,
      //align: 'start',
      width: 'flex-shrink-0 w-[7%]',
      header: () => <Trans message="Reversal Team"/>,
      classExt: (team) => moment.utc(team.start_time).tz(timezone).format('MM/DD/YYYY HH:mm') == moment().tz(timezone).format('MM/DD/YYYY HH:mm') ? '': 'text-red',
      body:  team => {
        return (
          <Fragment>
            {moment.utc(team.start_time).tz(timezone).format('MM/DD/YYYY HH:mm') >= moment().tz(timezone).format('MM/DD/YYYY HH:mm') ? (<>
            <DialogTrigger type="modal">
              <Button size={'3xs'} variant="outline" color="orange">
                <Trans message="AI Common Logic" />
              </Button>
              <ReversalTeamDialog id={team.id} prompt={team.reversal_team_prompt} team_name={team.team_name}/>
            </DialogTrigger>
            </>):(<></>)}
          </Fragment>
        );
      },    
    },

    {
      key: 'reversal_total',
      allowsSorting: false,
      //align: 'start',
      width: 'flex-shrink-0 w-[7%]',
      header: () => <Trans message="Reversal Total"/>,
      classExt: (team) => moment.utc(team.start_time).tz(timezone).format('MM/DD/YYYY HH:mm') == moment().tz(timezone).format('MM/DD/YYYY HH:mm') ? '': 'text-red',
      body:  team => {
        return (
          <Fragment>
            {moment.utc(team.start_time).tz(timezone).format('MM/DD/YYYY HH:mm') >= moment().tz(timezone).format('MM/DD/YYYY HH:mm') ? (<>
              <DialogTrigger type="modal">
              <Button size={'3xs'} variant="outline" color="orange">
                <Trans message="AI Common Logic" />
              </Button>
              <ReversalTotalDialog id={team.id} prompt={team.reversal_total_prompt} team_name={team.team_name}/>
            </DialogTrigger>
            </>):(<></>)}
          </Fragment>
        );
      },    
    },

    {
      key: 'final_score',
      allowsSorting: false,
      align: 'center',
      width: 'flex-shrink-0 w-[7%]',
      header: () => <Trans message="Final Score"/>,
      classExt: (team) => moment.utc(team.start_time).tz(timezone).format('MM/DD/YYYY HH:mm') == moment().tz(timezone).format('MM/DD/YYYY HH:mm') ? '': 'text-red',
      body:  team => {
        return (
          <UpdateTeamField id={team.id} field_name='final_score' val={team.final_score}/>
        );
      },    
    },

    {
      key: 'final_team_win',
      allowsSorting: false,
      //align: 'start',
      width: 'flex-shrink-0 w-[7%]',
      header: () => <Trans message="Final Team Win"/>,
      classExt: (team) => moment.utc(team.start_time).tz(timezone).format('MM/DD/YYYY HH:mm') == moment().tz(timezone).format('MM/DD/YYYY HH:mm') ? '': 'text-red',
      body:  team => {
        return (
          <UpdateTeamField id={team.id} field_name='final_team_win' val={team.final_team_win}/>
        );
      },    
    },

    {
      key: 'final_total_score',
      allowsSorting: false,
      //align: 'start',
      width: 'flex-shrink-0 w-[7%]',
      header: () => <Trans message="Final Total Score"/>,
      classExt: (team) => moment.utc(team.start_time).tz(timezone).format('MM/DD/YYYY HH:mm') == moment().tz(timezone).format('MM/DD/YYYY HH:mm') ? '': 'text-red',
      body:  team => {
        return (
          <UpdateTeamField id={team.id} field_name='final_total_score' val={team.final_total_score}/>
        );
      },    
    },

    {
      key: 'team_prediction_wl',
      allowsSorting: false,
      //align: 'start',
      width: 'flex-shrink-0 w-[7%]',
      header: () => <Trans message="Team Prediction W/L"/>,
      classExt: (team) => moment.utc(team.start_time).tz(timezone).format('MM/DD/YYYY HH:mm') == moment().tz(timezone).format('MM/DD/YYYY HH:mm') ? '': 'text-red',
      body:  team => {
        return (
          <UpdateTeamField id={team.id} field_name='team_prediction_wl' val={team.team_prediction_wl}/>
        );
      },    
    },

    {
      key: 'total_prediction_wl',
      allowsSorting: false,
      //align: 'start',
      width: 'flex-shrink-0 w-[7%]',
      header: () => <Trans message="Total Prediction W/L"/>,
      classExt: (team) => moment.utc(team.start_time).tz(timezone).format('MM/DD/YYYY HH:mm') == moment().tz(timezone).format('MM/DD/YYYY HH:mm') ? '': 'text-red',
      body:  team => {
        return (
          <UpdateTeamField id={team.id} field_name='total_prediction_wl' val={team.total_prediction_wl}/>
        );
      },    
    },

    {
      key: 'history',
      allowsSorting: false,
      //align: 'start',
      width: 'flex-shrink-0 w-[7%]',
      header: () => <Trans message="History"/>,
      classExt: (team) => moment.utc(team.start_time).tz(timezone).format('MM/DD/YYYY HH:mm') == moment().tz(timezone).format('MM/DD/YYYY HH:mm') ? '': 'text-red',
      body:  team => {
        return (
          <Fragment>
            {team.items.length ? (<>
              <DialogTrigger type="modal">
              <Button size={'3xs'} variant="outline" color="orange">
                <ViewListIcon/>
              </Button>
              <HistoryDialog items={team.items} team_name={team.team_name}/>
            </DialogTrigger>
            </>):(<></>)}
          </Fragment>
        );
      },    
    },

  ];
  if (nbaDate) {
  return (
    <>
    <DataNbaTablePage
      className='p-0 md:p-0'
      endpoint={`nbas/${nbaDate}`}
      columns={columnConfig}
      enableSelection={false}
      emptyStateMessage={
        <DataTableEmptyStateMessage
          image={softwareEngineerSvg}
          title={<Trans message="No nba team" />}
          filteringTitle={<Trans message="No matching nba team" />}
        />
      }
      nbaDate={nbaDate}
    />
    </>
  );
} else {
  return (<>
  
  </>);
}
}


export function ReversalTeamDialog({id, prompt, team_name}: {id: string|number, prompt: string, team_name: string}) {
  const form = useForm<ReversalTeamPayload>({
    defaultValues: {prompt: prompt, id: id},
  });
  const {formId, close} = useDialogContext();
  const reversalTeam = useReversalTeam(form);
  const {trans} = useTrans();

  return (
    <Dialog size='2xl'>
      <DialogHeader>
        <Trans message="Reversal Team" />  <b>{team_name}</b>
      </DialogHeader>
      <DialogBody>
        <Form
          form={form}
          id={formId}
          onSubmit={() => {
            reversalTeam.mutate(form.getValues());
          }}
        >
          <FormTextField
            name="prompt"
            autoFocus
            label={trans({message: 'Reversal Team Prompt'})}
            inputElementType="textarea"
            rows={15}
            required
          />
        </Form>
      </DialogBody>
      <DialogFooter>
        <Button variant="text" onClick={close}>
          <Trans message="Cancel" />
        </Button>
        <Button
          variant="flat"
          color="orange"
          type="submit"
          form={formId}
          disabled={reversalTeam.isPending}
        >
          <Trans message="Run" />
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export function ReversalTotalDialog({id, prompt, team_name}: {id: string|number, prompt: string, team_name: string}) {
  const form = useForm<ReversalTotalPayload>({
    defaultValues: {prompt: prompt, id: id},
  });
  const {formId, close} = useDialogContext();
  const reversalTotal = useReversalTotal(form);
  const {trans} = useTrans();

  return (
    <Dialog size='2xl'>
      <DialogHeader>
        <Trans message="Reversal Total" /> <b>{team_name}</b>
      </DialogHeader>
      <DialogBody>
        <Form
          form={form}
          id={formId}
          onSubmit={() => {
            reversalTotal.mutate(form.getValues());
          }}
        >
          <FormTextField
            name="prompt"
            autoFocus
            label={trans({message: 'Reversal Total Prompt'})}
            inputElementType="textarea"
            rows={15}
            required
          />
        </Form>
      </DialogBody>
      <DialogFooter>
        <Button variant="text" onClick={close}>
          <Trans message="Cancel" />
        </Button>
        <Button
          variant="flat"
          color="orange"
          type="submit"
          form={formId}
          disabled={reversalTotal.isPending}
        >
          <Trans message="Run" />
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export function HistoryDialog({items, team_name}: {items: any, team_name?: string}) {
  const {close} = useDialogContext();
  return (
    <Dialog size='800px'>
      <DialogHeader>
        <Trans message="View History" /> <b>{team_name}</b>
      </DialogHeader>
      <DialogBody>
        <HistoryInfo items={items} key={team_name}/>
      </DialogBody>
      <DialogFooter>
        <Button onClick={() => close()}>
          <Trans message="Close" />
        </Button>
      </DialogFooter>
    </Dialog>
  );
}

export function UpdateTeamField({id, field_name, val}:{id:number|string, field_name: string, val: any}) {

  const [value, setValue] = useState('');
  const mutation = useUpdateTeamField(id, field_name, val); // Hook at top level

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(newValue);
    mutation.mutate({ id, key: field_name, val: newValue });
  };

  return (
    <Fragment>
        <span>
          <div className='item-center ml-10 mr-10'>
          <TextField
          className='form-input w-full'
          name={`${id}_${field_name}`}
          key={`${id}_${field_name}`}
          value={val}
          onChange={handleChange}         
        />
          </div>
        </span>
      </Fragment>
  );
}


